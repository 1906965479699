import PropTypes from "prop-types";
import { memo } from "react";
import { components } from "react-select";

const DestinationInputValueFormat = props => {
	const { data } = props;

	return (
		<components.SingleValue {...props}>
			<div className="sdp-search-form__suggestion-label">{data.label}</div>
		</components.SingleValue>
	);
};

DestinationInputValueFormat.propTypes = {
	data: PropTypes.object,
	getStyles: PropTypes.func,
};

export default memo(DestinationInputValueFormat);
